import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export default class Email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      to: "",
      cc: "",
      bcc: "",
      body: "",
      subject: ""
    };
  }

  setTo(e) {
    this.setState({...this.state, to: e.target.value});
    this.props.onMailChange({...this.state, to: e.target.value});
  }

  setCc(e) {
    this.setState({...this.state, cc: e.target.value});
    this.props.onMailChange({...this.state, cc: e.target.value});
  }

  setBcc(e) {
    this.setState({...this.state, bcc: e.target.value});
    this.props.onMailChange({...this.state, bcc: e.target.value});
  }

  setSubject(e) {
    this.setState({...this.state, subject: e.target.value});
    this.props.onMailChange({...this.state, subject: encodeURI(e.target.value)});
  }

  setBody(e) {
    this.setState({...this.state, body: e.target.value});
    this.props.onMailChange({...this.state, body: encodeURI(e.target.value)});
  }

  render() {
    return (
      <Form>
        <FormGroup>
          <Input type="email" name="email" id="exampleEmail" onChange={(e) => this.setTo(e)} placeholder="To:" />
        </FormGroup>
        <FormGroup>
          <Input type="email" name="cc" id="exampleEmail" onChange={(e) => this.setCc(e)} placeholder="CC:" />
        </FormGroup>
        <FormGroup>
          <Input type="email" name="bcc" id="exampleEmail" onChange={(e) => this.setBcc(e)} placeholder="BCC:" />
        </FormGroup>
        <FormGroup>
          <Input type="text" name="subject" id="exampleEmail" onChange={(e) => this.setSubject(e)} placeholder="Subject" />
        </FormGroup>
        <FormGroup>
          <Input type="textarea" name="text" id="exampleText" onChange={(e) => this.setBody(e)} placeholder="E-Mail Body..."/>
        </FormGroup>
      </Form>
    );
  }
}
