import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

export default class Navigation extends React.Component {
  constructor(props){
    super(props)
  }
  setIndex(idx) {
    this.props.onNavigate(idx);
  }
  render() {
    return (
      <ButtonGroup>
        <Button outline={this.props.index !== "mail"} color="primary" onClick={() => this.setIndex("mail")}>E-Mail</Button>
        <Button outline={this.props.index !== "call"} color="primary" onClick={() => this.setIndex("call")}>Call to</Button>
      </ButtonGroup>
    );
  }
}
