import React from 'react';
import { InputGroup, InputGroupAddon, Button, Input } from 'reactstrap';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from 'axios';

export default class Link extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      uri: props.uri,
      shortUrl: ''
    };
  }
  componentDidUpdate(state, props) {
    // this.getShortUri(props.uri);
  }

  getShortUri(uri) {
    let req ='http://thelink.la/api-shorten.php?url=' + uri;
    axios.get(req).then((res) => {
      console.log("RESULT");
      console.log(res);
    })
  }
  render() {
    return (
        <InputGroup>
          <Input readOnly value={this.props.uri} />
          <InputGroupAddon addonType="append">
            <CopyToClipboard text={this.props.uri}>
              <Button color="primary">Copy!</Button>
            </CopyToClipboard>
          </InputGroupAddon>
        </InputGroup>
    );
  }
};
