import React from 'react';
import queryString from 'query-string';

export default class Open extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    let query = ""
    let adds = ""
    if(params.proto == "mail") {
      query = "mailto:" + params.to;
      if(params.subject) {
        adds === "" ? adds += "?subject=" + params.subject : adds += "&subject=" + params.subject;
      }
      if(params.body) {
        adds === "" ? adds += "?body=" + params.body : adds += "&body=" + params.body;
      }
      window.location.href = query + adds
    }
    if(params.proto == "call") {
      query = "callto:" + params.number;
      window.location.href = query
    }
    return (
      <h2>
        Open link ...
      </h2>
    );
  }
}
