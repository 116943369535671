import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export default class About extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Container className="container-fluid">
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <h2>About gett.li</h2>
            <h4>A small service to generate browser friendly URLs</h4>
          </Col>
        </Row>
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <h4>Why?</h4>
            <p>I founded this site in a need of a https:// based url for
               mailto: and callto: links to place them in my evernote.</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <h4>Who?</h4>
            <p>My name is Fabian Schaper I am a blogger and software developer from Liechtenstein.</p>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }} style={{textAlign: 'center'}}>
            <a href="/">home</a>
          </Col>
        </Row>
      </Container>
    );
  }
}
