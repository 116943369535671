import React from 'react';
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

export default class Call extends React.Component {
  constructor(props) {
      super(props);
  }

  changeCall(e) {
    this.props.onCallChange(e.target.value);
  }

  render() {
    return (
      <Form>
        <FormGroup>
          <Input type="text" name="email"
            onChange={(e) => this.changeCall(e)} id="exampleEmail" placeholder="Phone no:" />
        </FormGroup>
      </Form>
    );
  }
}
