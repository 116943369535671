import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './home/Home';
import Open from './open/Open';
import About from './about/About';
import logo from './logo.svg';
import './App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <Route path="/" exact component={Home} />
          <Route path="/open" component={Open} />
          <Route path="/about" component={About} />
        </div>
      </Router>
    );
  }
}

export default App;
