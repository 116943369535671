import React from 'react';
import Navigation from '../navigation/Navigation';
import Email from '../email/Email';
import Call from '../call/Call';
import Link from '../link/Link';
import { Container, Row, Col } from 'reactstrap';

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      base: "https://gett.li/open?proto=",
      proto: "mail",
      mail: {
        to: "",
        subject: "",
        cc: "",
        body: "",
        bcc: ""
      },
      call:{
        no: ""
      }
    }
  }

  changeIdx(val) {
    this.setState({...this.state, proto: val});
  }

  getUri() {
    if(this.state.proto === "mail") {
      let mail = this.state.base + this.state.proto + "&to=" + this.state.mail.to;
      mail += this.state.mail.cc !== "" ? "&cc=" + this.state.mail.cc : "";
      mail += this.state.mail.bcc !== "" ? "&bcc=" + this.state.mail.bcc : "";
      mail += this.state.mail.subject !== "" ? "&subject=" + this.state.mail.subject : "";
      mail += this.state.mail.body !== "" ? "&body=" + this.state.mail.body : "";
      return mail;
    }
    if(this.state.proto === "call") {
      return this.state.base + this.state.proto + "&no=" + this.state.call.no;
    }
    return "";
  }

  setMail(mail) {
    this.setState({...this.state, mail: mail});
  }

  setCall(call) {
    this.setState({...this.state, call: { no: call }});
  }

  render() {
    return (
      <Container className="container-fluid">
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <h2>Welcome to gett.li</h2>
            <h4>A small service to generate browser friendly URLs</h4>
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <Navigation index={this.state.proto} onNavigate={(idx) => this.changeIdx(idx)} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            { this.state.proto === "mail" ? <Email onMailChange={(mail) => this.setMail(mail)} /> : <Call onCallChange={(call) => this.setCall(call)} /> }
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }}>
            <Link readOnly uri={this.getUri()} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm={{ size: 6, offset: 3 }} style={{textAlign: 'center'}}>
            <a href="/about">about</a>
          </Col>
        </Row>
      </Container>
    );
  }
}
